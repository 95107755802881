import React, { useState } from "react"
import LogOutLink from '../components/auth0/LogOutLink';

const LogOut = () => {


  return (
    <>
    
      <LogOutLink />
      

    </>
  )
}

export default LogOut
